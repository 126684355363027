<template>
  <span class="block text-gray-700 text-xs ml-3 tracking-tighter text-secondary">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'LabelFormProfile',
};
</script>
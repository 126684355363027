<template>
  <tw-container is="section" class="py-5">
    <div class="container mx-auto">
      <h1 class="text-4xl font-bold font-secondary text-secondary uppercase">
        Confirmación de pedido
      </h1>
    </div>
    <form @submit.prevent="checkForm">
      <div class="py-10 px-3">
        <section class="mb-8 container mx-auto">
          <h2 class="text-xl text-primary font-semibold mb-4">
            1. Información del sitio de trabajo
          </h2>
          <div class="my-3">
            <span
              class="text-xs font-bold text-secondary uppercase"
            >Ubicacion: </span>{{ location.value }}
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 -mx-3">
            <div class="px-3">
              <LabelFormProfile>Responsable de la recepción*</LabelFormProfile>
              <InputFormProfile
                id="responsable"
                v-model="form.responsable"
                name="responsable"
                placeholder="Escriba un responsable de la recepción"
              />
              <ErrorField>{{ errors.responsable }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Teléfono de contacto*</LabelFormProfile>
              <InputFormProfile
                id="phone"
                v-model="form.phone"
                name="phone"
                placeholder="Escriba un teléfono de contacto"
              />
              <ErrorField>{{ errors.phone }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Colonia*</LabelFormProfile>
              <InputFormProfile
                id="city"
                v-model="form.city"
                name="city"
                placeholder="Nombre de la colonia"
              />
              <ErrorField>{{ errors.city }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Calle*</LabelFormProfile>
              <InputFormProfile
                id="street"
                v-model="form.street"
                name="street"
                placeholder="Nombre de la calle"
              />
              <ErrorField>{{ errors.street }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Numero exterior*</LabelFormProfile>
              <InputFormProfile
                id="exterior_number"
                v-model="form.exterior_number"
                name="exterior_number"
                placeholder="Numero exterior del lugar de trabajo"
              />
              <ErrorField>{{ errors.exterior_number }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Codigo Postal*</LabelFormProfile>
              <InputFormProfile
                id="postal_code"
                v-model="form.postal_code"
                name="postal_code"
                placeholder="Codigo postal del lugar de trabajo"
              />
              <ErrorField>{{ errors.postal_code }}</ErrorField>
            </div>
            <div class="px-3">
              <LabelFormProfile>Ventana de entrega*</LabelFormProfile>
              <SelectFormProfile
                v-model="form.delivery_hours"
                :options="opciones"
              />
              <ErrorField>{{ errors.delivery_hours }}</ErrorField>
            </div>
            <!-- <BasicInput placeholder="Nombre de contacto de entrega" />
            <BasicInput placeholder="Celular de contacto de entrega" />
            <BasicInput placeholder="Instrucciones especiales (opcional)" />
            <select
              v-model="form.delivery_hours"
              class="flex-1 block w-full h-full rounded px-2 mb-5"
              placeholder="kek"
            >
              <option value="">Selecciona una ventana de entrega</option>
              <option>8:00AM - 10:00AM</option>
              <option>9:00AM - 11:00AM</option>
              <option>10:00AM - 12:00PM</option>
              <option>11:00AM - 1:00PM</option>
              <option>12:00PM - 2:00PM</option>
              <option>1:00PM - 3:00PM</option>
              <option>2:00PM - 4:00PM</option>
              <option>3:00PM - 5:00PM</option>
              <option>4:00PM - 6:00PM</option>
              <option>5:00PM - 7:00PM</option>
            </select> -->
          </div>
        </section>
        <section class="mb-6 container mx-auto">
          <h2 class="text-xl text-primary font-semibold mb-3">
            2. Contrato de Renta
          </h2>
          <div class="text-sm text-gray-700 mb-2">
            Acepta el siguiente contrato de renta para reaizar tu pedido, se te
            enviará una copia por correo electrónico.
          </div>
          <div
            class="bg-gray-100 p-3 rounded-md border shadow-sm h-64 overflow-auto mb-4"
          >
            <strong class="mb-2">
              CONDICIONES GENERALES DE RENTA. (CLÁUSULAS:)
            </strong>
            <p class="mb-4">
              El contrato se celebra por el periodo de días especificado en la
              carátula de este contrato, y de acuerdo con las tarifas
              estipuladas apartir de la fecha en que fue entregado el equipo,
              especificado también en la carátula de este contrato, objeto de
              este contrato. En el caso de que el equipo arrendado sujeto a este
              contrato sea devuelto en un periodo que no coincida exactamente
              con el estipulado,
            </p>
            <p class="mb-4">El arrendatario se obliga a:</p>
            <p class="mb-4">
              Pagar la renta estipulada desde el momento en que el equipo llegue
              a las instalaciones del arrendatario, hasta que sea devuelto a la
              bodega del arrendador y recibido de conformidad.
            </p>
            <p class="mb-4">
              Pagar de acuerdo a las condiciones estipuladas en la carátula
              deeste contrato, en el domicilio del arrendatario, sin necesidad
              de previo cobro, debiendo pagar siempre el importe íntegro de
              dicha rentapor adelantado.
            </p>
            <p class="mb-4">
              Cumplir con el plazo establecido en la caratula de este contrato
              de manera forzosa y en caso de incumplimiento cubrir las cuotas
              faltantes.
            </p>
            <p class="mb-4">
              Responder de todas las perdidas, deterioros o perjuicios que los
              bienes arrendados sufran, aun los que provengan en caso fortuito o
              de fuerza mayor.
            </p>
            <p class="mb-4">
              El equipo deberá ser operado de acuerdo a las instrucciones del
              arrendador debiendo utilizarse únicamente combustible, lubricantes
              y partes de repuesto aprobados por este, quien en cualquier
              momento podrá inspeccionar y verificar el estado del mismo.
            </p>
            <p class="mb-4">
              Responder de toda clase de daños y perjuicios que se ocasionen
              sobre los bienes o personas de las partes o de terceras y que sean
              consecuencia directa o indirecta del uso de los bienes arrendados
              o se deriven del cumplimiento de este contrato. Otorgar y
              contratar en favor del arrendatario los seguros y fianzas que el
              arrendador determina como garantía del cumplimiento de las
              obligaciones establecidas en este contrato.
            </p>
            <p class="mb-4">
              Pagar todos los derechos de impuestos y gastos que ocasione la
              firma y cumplimiento de este contrato, misma que estará a lo
              establecido en la cláusula primera del presente instrumento.
            </p>
            <p class="mb-4">
              Pagar al arrendador en caso de demora en el pago de cualquier
              obligación de dinero establecido, un interés que se fije en el
              tipo 40% anual sin perjuicio de las acciones que establece la ley
              en favor del arrendador.
            </p>
            <p class="mb-4">
              El bien arrendado estará trabajando en la dirección señalada en la
              carátula de este contrato. El arrendatario deberá usar los bienes
              arrendados precisamente en la obra estipulada y no podrá
              transportarlosa otra obra sin previo consentimiento dado por
              escrito por el arrendador
            </p>
            <p class="mb-4">
              El arrendatario declara haber examinado cuidadosamente el equipo
              materia de este arrendamiento y comprobado que la descripción e
              identificación del mismo corresponden al descrito en este
              contrato. Declara igualmente que el equipo se encuentra en
              condiciones perfectas de operación y del todo adecuado y
              conveniente al trabajo que se va a destinar, conociendo las
              limitaciones en combustible y lubricantes que deberán emplearse en
              la operación del mencionado equipo.
            </p>
            <p class="mb-4">
              El arrendatario deberá de dar aviso inmediatamente de cualquier
              malfuncionamiento del equipo o de problemas en su mantenimiento.
            </p>
            <p class="mb-4">
              El arrendatario otorga desde este momento su autorización más
              amplia al arrendador para que sin previa notificación retire la
              maquinaria arrendada del sitio en que el arrendatario la haya
              colocado si los términos de este contrato no son total o
              absolutamente cumplidos por el arrendatario y los gastos que se
              originen por este motivo serán a cargo del arrendatario
            </p>
            <p class="mb-4">
              El arrendador no será responsable por todas las reparaciones o
              partes del equipo que no hayan sido autorizadas expresamente por
              escrito por el mismo arrendador.
            </p>
            <p class="mb-4">
              A la terminación de este contrato el arrendatario deberá devolver
              al arrendador los bienes arrendados en el mismo estado en el que
              los recibieron, salvo el deterioro que sea consecuencia del uso
              normal de los mismos
            </p>
            <p class="mb-4">
              El arrendatario se obliga a celebrar el contrato definitivo de
              arrendamiento en el momento en el que el arrendador se lo solicite
              y faculta expresamente para recibir el equipo y para la firma del
              presente a la persona cuya firma aparece en estecontrato.
            </p>
            <p class="mb-4">
              El arrendatario se obliga a pagar los desperfectos y reparaciones
              derivados de un mal uso, mala operación, descuido del operador,
              accidente o actos de vandalismo como el robo o destrucción de
              aditamentos, así como responder por las reparacionesque se puedan
              derivar por la sobrecarga por arriba de su capacidad indicada en
              el anexo “a” de este contrato(especificaciones del equipo)
            </p>
            <p class="mb-4">
              El presente contrato cuenta con seguro básico contra robo con
              violencia, cualquier daño a terceros o al equipo será
              responsabilidad del arrendatario y se obliga a pagar las rentas en
              caso de que el equipo se encuentre en reparación.
            </p>
            <p class="mb-4">
              El arrendatario se obliga a pagar el deducible y/o coaseguro del
              seguro básico en caso de siniestro.
            </p>
            <p class="mb-4">
              El arrendatario se compromete a estar verificando el mantenimiento
              preventivo necesario a la maquinaria en renta, con el fin de
              mantener en condiciones óptimas de servicio la maquinaria.
            </p>
            <p class="mb-4">
              El arrendador se compromete a hacer entrega del equipo y, al
              término del período de arrendamiento a recoger el equipo en la
              dirección señalada en la carátula.
            </p>
            <p class="mb-4">
              El Horario de Atención al cliente del Arrendador es de Lunes a
              Viernes de 9am a 5:30pm y los Sábados de 9am a 12pm
            </p>
            <p class="mb-4">
              El Arrendador nose hace responsablepor daños a terceros que
              provoque el equipo. La responsabilidad es del Arrendatario.
            </p>
            <p class="mb-4">
              Terminación de contrato.-Serán las establecidas en el art. 2377
              del Código Civil en el estado de Nuevo León.
            </p>
            <p class="mb-4">
              Las partes están totalmente de acuerdo con lo escrito en el
              presente instrumento por lo que ratifican desde este momento de su
              puño y letra en la ciudad de Monterrey N. L.
            </p>
          </div>
          <div class="">
            <input id="tos" v-model="form.tos" type="checkbox" name="tos" />
            <label
              for="tos"
              class="ml-2 cursor-pointer font-semibold text-sm text-gray-700"
            >
              Acepto los términos y condiciones del contrato de servicio para
              renta de maquinaria.
            </label>
          </div>
        </section>
        <section class="mb-8 container mx-auto">
          <h2 class="text-xl text-primary font-semibold mb-4">
            3. Información de pago
          </h2>
          <div class="text-sm text-gray-700 mb-2">
            Al continuar seras redirigido al checkout de
            <strong>conekta</strong> para realizar el pago de la orden de renta.
          </div>
          <div class="grid gap-4 grid-cols-4">
            <!-- <BasicInput class="col-span-2" placeholder="Número de tarjeta" />
            <BasicInput placeholder="Fecha de Expiración (MM / AA)" />
            <BasicInput placeholder="CVV" />
            <BasicInput placeholder="Nombre en la Tarjeta" />
            <BasicInput class="col-span-3" placeholder="Dirección de Facturación" />
            <BasicInput placeholder="Unit #" />
            <BasicInput placeholder="Ciudad de Facturación" />
            <BasicInput placeholder="Estado" />
            <BasicInput placeholder="Código Postal" /> -->
          </div>
        </section>
      </div>
      <div class="flex justify-center">
        <router-link :to="{ name: 'Quote' }">
          <button class="btn btn-gray">
            <r-icon type="backspace" class="h-6 mr-3 inline-block" />
            Volver
          </button>
        </router-link>
        <button
          class="ml-4 btn btn-orange-success"
          :disabled="!meta.valid || creating"
        >
          <r-icon type="check-circle" class="h-6 mr-3 inline-block" />
          {{ creating ? "Creando Checkout..." : "Continuar" }}
        </button>
      </div>
    </form>
  </tw-container>
</template>

<script lang="ts">
// import BasicInput from '@/components/inc/inputs/BasicInput';
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import SelectFormProfile from '@/components/UI/SelectFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';
import { isAuthenticated } from '@/store/security';
import {
  useEndDate,
  useQuote,
  useStartDate,
  useLocation,
  createCheckout,
  useCreating,
} from '@/store/shoppingCart';
import { defineComponent } from 'vue';
import { useForm, useField } from 'vee-validate';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    SelectFormProfile,
    ErrorField,
  },
  setup() {
    const creating = useCreating();
    const quote = useQuote();
    const startDate = useStartDate();
    const endDate = useEndDate();
    const location = useLocation();

    const schema = {
      responsable: 'min:3|max:60|alpha_spaces|required',
      street: 'min:3|max:60|required',
      city: 'min:3|max:60|required',
      postal_code: 'numeric|min:3|max:10|required',
      exterior_number: 'max:10|required',
      phone: 'numeric|min:10|max:20|required',
      delivery_hours: 'required',
      tos: 'required',
    };

    const { errors, values, meta } = useForm({
      initialValues: {
        responsable: '',
        city: '',
        street: '',
        postal_code: '',
        exterior_number: '',
        phone: '',
        delivery_hours: '',
        tos: null,
      },
      validationSchema: schema,
    });

    const labels = {
      responsable: 'Responsable',
      street: 'Calle',
      city: 'Ciudad',
      postal_code: 'Codigo postal',
      exterior_number: 'Numero Exterior',
      phone: 'Teléfono de contacto',
      delivery_hours: 'Horario de entrega',
      tos: 'Terminos del servicio',
    };

    Object.entries(labels).forEach(([key, val]) => {
      useField(key, null, {label: val});
    });

    return {
      errors,
      form: values,
      meta,
      creating,
      quote,
      startDate,
      endDate,
      location,
    };
  },
  data: () => ({
    entrega: '',
    opciones: [
      { name: '8:00AM - 10:00AM', value: '8:00AM - 10:00AM' },
      { name: '9:00AM - 11:00AM', value: '9:00AM - 11:00AM' },
      { name: '10:00AM - 12:00PM', value: '10:00AM - 12:00PM' },
      { name: '11:00AM - 1:00PM', value: '11:00AM - 1:00PM' },
      { name: '12:00PM - 2:00PM', value: '12:00PM - 2:00PM' },
      { name: '1:00PM - 3:00PM', value: '1:00PM - 3:00PM' },
      { name: '2:00PM - 4:00PM', value: '2:00PM - 4:00PM' },
      { name: '3:00PM - 5:00PM', value: '3:00PM - 5:00PM' },
      { name: '4:00PM - 6:00PM', value: '4:00PM - 6:00PM' },
      { name: '5:00PM - 7:00PM', value: '5:00PM - 7:00PM' },
    ],
    // form: {
    //   delivery_hours: '',
    // },
  }),
  methods: {
    checkForm() {
      if (isAuthenticated() && this.quote != null && !this.creating) {
        createCheckout({
          delivery_hours: this.form.delivery_hours,
          city: this.form.city,
          street: this.form.street,
          postal_code: this.form.postal_code,
          exterior_number: this.form.exterior_number,
          location: this.location,
          contact_name: this.form.responsable,
          contact_phone: this.form.phone,
          tos: this.form.tos,
        }).then((response) => {
          console.log(response);
          window.location.href = response?.data.url;
        });
      }
    },
  },
});
</script>

<style></style>

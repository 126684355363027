
// import BasicInput from '@/components/inc/inputs/BasicInput';
import LabelFormProfile from '@/components/UI/LabelFormProfile.vue';
import InputFormProfile from '@/components/UI/InputFormProfile.vue';
import SelectFormProfile from '@/components/UI/SelectFormProfile.vue';
import ErrorField from '@/components/UI/ErrorField.vue';
import { isAuthenticated } from '@/store/security';
import {
  useEndDate,
  useQuote,
  useStartDate,
  useLocation,
  createCheckout,
  useCreating,
} from '@/store/shoppingCart';
import { defineComponent } from 'vue';
import { useForm, useField } from 'vee-validate';

export default defineComponent({
  components: {
    LabelFormProfile,
    InputFormProfile,
    SelectFormProfile,
    ErrorField,
  },
  setup() {
    const creating = useCreating();
    const quote = useQuote();
    const startDate = useStartDate();
    const endDate = useEndDate();
    const location = useLocation();

    const schema = {
      responsable: 'min:3|max:60|alpha_spaces|required',
      street: 'min:3|max:60|required',
      city: 'min:3|max:60|required',
      postal_code: 'numeric|min:3|max:10|required',
      exterior_number: 'max:10|required',
      phone: 'numeric|min:10|max:20|required',
      delivery_hours: 'required',
      tos: 'required',
    };

    const { errors, values, meta } = useForm({
      initialValues: {
        responsable: '',
        city: '',
        street: '',
        postal_code: '',
        exterior_number: '',
        phone: '',
        delivery_hours: '',
        tos: null,
      },
      validationSchema: schema,
    });

    const labels = {
      responsable: 'Responsable',
      street: 'Calle',
      city: 'Ciudad',
      postal_code: 'Codigo postal',
      exterior_number: 'Numero Exterior',
      phone: 'Teléfono de contacto',
      delivery_hours: 'Horario de entrega',
      tos: 'Terminos del servicio',
    };

    Object.entries(labels).forEach(([key, val]) => {
      useField(key, null, {label: val});
    });

    return {
      errors,
      form: values,
      meta,
      creating,
      quote,
      startDate,
      endDate,
      location,
    };
  },
  data: () => ({
    entrega: '',
    opciones: [
      { name: '8:00AM - 10:00AM', value: '8:00AM - 10:00AM' },
      { name: '9:00AM - 11:00AM', value: '9:00AM - 11:00AM' },
      { name: '10:00AM - 12:00PM', value: '10:00AM - 12:00PM' },
      { name: '11:00AM - 1:00PM', value: '11:00AM - 1:00PM' },
      { name: '12:00PM - 2:00PM', value: '12:00PM - 2:00PM' },
      { name: '1:00PM - 3:00PM', value: '1:00PM - 3:00PM' },
      { name: '2:00PM - 4:00PM', value: '2:00PM - 4:00PM' },
      { name: '3:00PM - 5:00PM', value: '3:00PM - 5:00PM' },
      { name: '4:00PM - 6:00PM', value: '4:00PM - 6:00PM' },
      { name: '5:00PM - 7:00PM', value: '5:00PM - 7:00PM' },
    ],
    // form: {
    //   delivery_hours: '',
    // },
  }),
  methods: {
    checkForm() {
      if (isAuthenticated() && this.quote != null && !this.creating) {
        createCheckout({
          delivery_hours: this.form.delivery_hours,
          city: this.form.city,
          street: this.form.street,
          postal_code: this.form.postal_code,
          exterior_number: this.form.exterior_number,
          location: this.location,
          contact_name: this.form.responsable,
          contact_phone: this.form.phone,
          tos: this.form.tos,
        }).then((response) => {
          console.log(response);
          window.location.href = response?.data.url;
        });
      }
    },
  },
});

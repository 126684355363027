<template>
  <input
    class="border mt-1 flex rounded-md shadow-sm w-full py-2 px-3 placeholder-gray-600 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
    :value="modelValue"
    :type="type"
    :name="name"
    @input="$emit('update:modelValue', $event.target.value)"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'InputFormProfile',
  props: {
    modelValue: String,
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      required: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange (e) {
      if (e.target.type === 'file') {
        if (!e.target.multiple) {
          this.$emit('update:modelValue', e.target.files[0]);
        } else{
          this.$emit('update:modelValue', e.target.files);
        }
      }
    },
  },
};
</script>

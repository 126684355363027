<template>
  <div v-moveout="clickedOutside" class="mt-1 relative">
    <div @click="handleInput">
      <button 
        ref="input" 
        type="button"         
        tabindex="0" 
        aria-haspopup="listbox" 
        aria-expanded="true" 
        aria-labelledby="listbox-label" 
        :class="inputClass"
      >
        <span class="flex items-center">
          <!-- <img src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" /> -->
          <span class="block truncate text-gray-600 capitalize">
            {{ item.name }}
          </span>
        </span>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
    </div>
    <div
      v-show="showOptions"
      :class="dropdownClass"
    >
      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">        
        <li
          v-for="option in options"
          id="listbox-item-0"
          :key="option.value"
          role="option"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9  hover:bg-blue-200"                    
          @click="handleClick(option)"          
        >
          <div class="flex items-center">
            <!-- <img src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" /> -->
            <span class="block font-normal truncate placeholder-gray-700 text-xs capitalize">
              {{ option.name }}
            </span>
          </div>
          <!-- Heroicon name: solid/check -->
          <!-- <span class="absolute inset-y-0 right-0 flex items-center pr-4">            
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </span> -->
        </li>

        <!-- More items... -->
      </ul>
    </div>
  </div>  
</template>

<script>
import { Moveout } from '@/directives/Moveout';
export default {
  directives: { Moveout },   
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Enter text here.',
    },
    options: {
      type: Array,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: 'border bg-white rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline',
    },
    dropdownClass: {
      type: String,
      required: false,      
      default: 'absolute w-full z-50 bg-white border border-gray-300 mt-1 mh-48 overflow-hidden overflow-y-scroll rounded-md shadow-md',
    },    
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showOptions: false,
      chosenOption: '',
      searchTerm: '',
      item: {
        name: 'Seleccione una Opción',
        value: 0,
      },
    };
  },  
  computed: {
    searchResults() {
      return this.options.filter((item) => {
        return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
  },
  mounted() {
    const selected = this.options.find((item) => {
      return item.value === this.modelValue;
    });
    this.item = selected != null ? selected : this.item;
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', '');
      this.chosenOption = '';
    },

    handleInput(e) {
      // this.searchTerm = evt.target.value;            
      this.showOptions = !this.showOptions;
      this.$refs.input.focus();
      e.stopPropagation();
    },

    handleClick(item) {            
      // this.$emit('chosen', item);
      // this.chosenOption = item.name;
      this.item = item;
      this.$emit('update:modelValue', item.value);      
      // this.$refs.input.focus();  
      this.showOptions = false;
    },

    clickedOutside() {
      this.showOptions = false;
      // if (!this.chosenOption) {
      //   this.$emit('input', '');
      // }
    },
  },
};
</script>
